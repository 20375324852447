// @flow
import * as React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import styled from 'styled-components'

type Props = {
  location: {
    key: string
  }
  data: {
    staticBlock: {
      story: any
    }
  }
}

export default function Route404(props: Props) {
  const { story } = props.data.staticBlock
  return (
    <Layout partialStateUpdates={story.partialStateUpdates}>
      <Headline>Wir sind verwundert - Diese Seite haben wir leider nicht gefunden (404)</Headline>
      <Story key={props.location.key} story={story} />
    </Layout>
  )
}

export const query = graphql`
  query {
    staticBlock(identifier: { eq: "404_Page_Not_Found" }) {
      story
    }
  }
`

const Headline = styled.h1`
  text-align: center;
  margin-top: 40px;
`
